<template>
    <div>
        <div class="shouzhong">
            <div class="name" :style="`flex:${labelWidth} 0 0`">关系：</div>
            <div class="shouzhongCon">
                <el-checkbox-group v-model="relation">
                    <div>
                        <p>
                            <el-checkbox label="key1">与以下内容建立关系网络的用户</el-checkbox>
                        </p>
                        <p v-if="relation.includes('key1')">
                            <el-select v-model="connections.connections" placeholder="请选择主页"
                                        multiple clearable style="width:50%" @change="setConnections" value-key="id">
                                <el-option :label="page.name" :value="page"
                                            v-for="(page) in currentPageList"
                                            :key="page.id" :disabled="(connectionsSelectDisabled(page) || !page.is_published)"></el-option>
                            </el-select>
                        </p>
                    </div>
                    <div>
                        <p>
                            <el-checkbox label="key2">与以下内容建立关系网络定位受众类似的人群</el-checkbox>
                        </p>
                        <p v-if="relation.includes('key2')">
                            <el-select v-model="connections.friends_of_connections"
                                        placeholder="请选择主页" multiple clearable style="width:50%" @change="setConnections" value-key="id">
                                <el-option :label="page.name" :value="page"
                                            v-for="(page) in currentPageList" :key="page.id" :disabled="!page.is_published"></el-option>
                            </el-select>
                        </p>
                    </div>
                    <div>
                        <p>
                            <el-checkbox label="key3">排除与以下内容建立关系网络的用户</el-checkbox>
                        </p>
                        <p v-if="relation.includes('key3')">
                            <el-select v-model="connections.excluded_connections"
                                        placeholder="请选择主页" multiple clearable style="width:50%" @change="setConnections" value-key="id">
                                <el-option :label="page.name" :value="page"
                                            v-for="(page) in currentPageList"
                                            :key="page.id" :disabled="(excludedConnectionsSelectDisabled(page) || !page.is_published)"></el-option>
                            </el-select>
                        </p>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>
<script>
    import {accountPages} from '@/api/creatAd.js'
    export default {
        props:['label-width','relationsMsg','pageList'],
        data() {
            return {
                connections:JSON.parse(JSON.stringify(this.relationsMsg)),
                accountPagesList:[],
                list:[],
                accountId:this.$store.getters.accountId
            }
        },
        mounted() {
            this.getAcoountPages();
        },  
        computed:{
            currentPageList(){
                if(this.pageList){
                    return this.pageList
                }else{
                    return this.accountPagesList
                }
            },
            relation:{
                // getter
                get: function () {
                    if(this.connections.connections && this.connections.connections.length){
                        this.list.push('key1')
                    }
                    if(this.connections.excluded_connections && this.connections.excluded_connections.length){
                        this.list.push('key3')
                    }
                    if(this.connections.friends_of_connections && this.connections.friends_of_connections.length){
                        this.list.push('key2')
                    }
                    return this.list
                },
                // setter
                set: function (newValue) {
                    this.list = Array.from(new Set(newValue))
                }
                
            }
        },
        methods: {
            getAcoountPages() {
                accountPages({accountId: this.accountId}).then(res => {
                    this.accountPagesList = res.data;
                })
            },
            connectionsSelectDisabled(a){
                if(a && this.connections.excluded_connections){
                    return this.connections.excluded_connections.some(b=>b.id===a.id);
                }
                return false;
            },
            excludedConnectionsSelectDisabled(a){
                if(a && this.connections.connections){
                    return this.connections.connections.some(b=>b.id===a.id);
                }
                return false;
            },
            setConnections(){
                console.log(this.connections);
                this.$emit('setReationsMsg',JSON.parse(JSON.stringify(this.connections)))
            }
        },
        watch:{
            relationsMsg:{
                handler(){
                    this.connections = JSON.parse(JSON.stringify(this.relationsMsg))
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss" scope>
    
</style>