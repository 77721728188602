<template>
    <div>
        <div class="shouzhong">
            <div class="name" :style="`flex:${labelWidth} 0 0`">受众设置：</div>
            <div class="shouzhongCon">
                <div class="list" v-for="(people,ind) in flexibleSpex.flexible_spec" :key="ind">
                    <p>
                        <span>包含至少符合以下条件之一的人
                            <tips :type="ind?'second':'first'"></tips>
                            <recommend-audience :list="multipleList" @confirm="confirmAdd($event,ind)" :targetUserId="targetUserId"></recommend-audience>
                        </span>
                        <span class="tools">
                            <span v-if="canSplitAudience(ind)" @click="splitAudience(ind)">拆分受众</span>
                            <span @click="deleAudience(ind)">删除</span>
                        </span>
                    </p>
                    <flexible-spec ref="flexibleSpec" :account-id="accountId+''" v-model="flexibleSpex.flexible_spec[ind]" @move-to="moveToExclude" move-to-tip="移动到排除受众"  :flexible-index="ind" :invaild_insterest="invaild_insterest"></flexible-spec>
                    <div v-show="invaild_insterest&&invaild_insterest.length" style="color:red">提示：存在无效的兴趣词，请删除后再提交</div>
                </div>
                <!-- 排除受众 -->
                <div v-if="hasExclude">
                    <div class="list">
                        <p>
                            <span>排除符合以下条件的受众<tips type="third"></tips></span>
                            <span class="tools">
                                <span @click="delExcludeAudience">删除</span>
                            </span>
                        </p>
                        <flexible-spec :account-id="accountId+''" v-model="flexibleSpex.notPeople[0]" move-to-tip="移动到缩小受众"  @move-to="moveToInclude" :flexible-index="0"></flexible-spec>
                    </div>
                </div>
                <el-button @click="addAudience('flexible_spec')" size="mini" type="text">缩小受众</el-button>
                <span style="margin:0 10px;" v-if="!hasExclude">或</span>
                <el-button @click="excludeAudience" size="mini" type="text" v-if="!hasExclude"> 排除 </el-button>
                <!-- <div>
                    <el-switch v-model="flexibleSpex.targeting_optimization"  active-value="expansion_all" inactive-value="none"></el-switch>
                    <span>扩展细分定位，有机会提升广告表现时，向更多用户推广</span>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import tips from '../../../createAd/components/targeting/tips'
    import flexibleSpec from '../../../createAd/components/targeting/FlexibleSpec'
    import recommendAudience from "@/views/adManagement/createAd/components/recommendAudience"
    import { mapState } from "vuex";
    export default {
        props:['label-width','flexibleSpecMsg','targetUserId','invaild_insterest'],
        data () {
            return {
                flexibleSpex:{flexible_spec:[],notPeople:[],targeting_optimization:'expansion_all'},
                accountId:this.$store.getters.accountId
            }
        },
        computed:{
            // 是否排除受众
            hasExclude() {
                if (this.flexibleSpex.notPeople && this.flexibleSpex.notPeople.length > 0  && this.flexibleSpex.notPeople[0]) {
                    return true;
                }
                return false;
            },
            ...mapState({
                multipleList:state => state.audiences.multipleAdsets
            })
        },
        components: {
            tips,
            flexibleSpec,
            recommendAudience
        },
        mounted() {
            this.flexibleSpex = JSON.parse(JSON.stringify(this.flexibleSpecMsg));
        },
        watch:{
            flexibleSpecMsg:{
                handler(){
                    this.flexibleSpex = JSON.parse(JSON.stringify(this.flexibleSpecMsg));
                    this.flexibleSpex.flexible_spec = this.flexibleSpex.flexible_spec?this.flexibleSpex.flexible_spec:[]
                    this.flexibleSpex.notPeople = this.flexibleSpex.notPeople && this.flexibleSpex.notPeople.length?this.flexibleSpex.notPeople:[];
                    if((!this.flexibleSpex.flexible_spec)||!(this.flexibleSpex.flexible_spec.length)){
                        console.log(this.flexibleSpex);
                        this.addAudience('flexible_spec');
                    }
                },
                deep:true
            },
            flexibleSpex:{
                handler(){
                    if(!this.flexibleSpex || !this.flexibleSpex.flexible_spec|| !this.flexibleSpex.flexible_spec.length){
                        return
                    }
                    this.$emit('setFexible',this.flexibleSpex)
                    // this.$emit('input',{...this.flexibleSpex})
                },
                deep:true
            }
        },
        methods: {
            // 默认空受众
            emptyFlexible() {
                return {
                    interests: [],
                    behaviors: [],
                    custom_audience: [],
                    education_schools: [],
                    education_statuses: [],
                    family_statuses: [],
                    income: [],
                    industries: [],
                    life_events: [],
                    relationship_statuses: [],
                    adworkemployer: [],
                    adworkposition: []
                };
            },
            
            // 是否可以拆分受众
            canSplitAudience(index) {
                if (this.flexibleSpex.flexible_spec.length !== 1) return false;
                let audience = this.flexibleSpex.flexible_spec[index];
                if (!audience) return false;
                let types = {};
                let n = 0;
                for (let k in audience) {
                    let kList = audience[k];
                    for (let i in kList) {
                        let item = kList[i];
                        if (!types[item.type]) {
                            types[item.type] = true;
                            n++;
                        }
                    }
                }
                return n > 1;
            },
            // 拆分受众
            splitAudience(index) {
                let audience = this.flexibleSpex.flexible_spec[0];
                let list = [];
                for (let key in audience) {
                    let v = audience[key];
                    let item = {};
                    item[key] = v;
                    if (v && v.length > 0) {
                        list.push(item);
                    }
                }
                // console.log('list',list);
                this.flexibleSpex.flexible_spec=list;
            },
            // 删除受众
            deleAudience(index){
                if (this.flexibleSpex.flexible_spec.length > 1) {
                    this.flexibleSpex.flexible_spec.splice(index, 1)
                } else {
                    this.$message({
                        type: 'warning',
                        message: '请至少保留一个受众'
                    })
                }
            },
            // 点击缩小受众
            addAudience(type) {
                this.flexibleSpex[type].push(this.emptyFlexible());
                let i = this.flexibleSpex[type].length;
                this.$set(this.flexibleSpex[type], i - 1, this.emptyFlexible());

            },
            // 移动到排除受众
            moveToExclude(type, flexibleObj, flexibleIndex) {
                // this.$set(this.contentList[this.activeIndex].flexible_spec[flexibleIndex], type, []);
                let v = flexibleObj;
                // console.log("moveToExclude", type, flexibleObj, flexibleIndex)
                if (this.flexibleSpex.notPeople.length === 0) {
                    let obj = this.emptyFlexible();
                    obj[type] = v;
                    this.$set(this.flexibleSpex.notPeople, 0, obj);
                } else {
                    this.$set(this.flexibleSpex.notPeople[0],type,v);
                }
            },
            // 点击排除受众
            excludeAudience() {
                this.flexibleSpex.excludeAudienceShow = true;
                this.addAudience('notPeople')
            },
            // 删除排除受众
            delExcludeAudience() {
                this.flexibleSpex.excludeAudienceShow = false;
                this.flexibleSpex.notPeople = [];
            },
            // 移动到受众
            moveToInclude(type, flexibleObj, flexibleIndex) {
                let v = flexibleObj;
                let spec = this.flexibleSpex.flexible_spec;
                // console.log("moveToInclude", type, v, flexibleIndex, spec)
                if (spec.length === 0) {
                    let obj = this.emptyFlexible();
                    obj[type] = v;
                    spec.push(obj);
                    this.$set(this.flexibleSpex, 'flexible_spec', spec);
                } else {
                    let i = spec.length - 1;
                    this.$set(this.flexibleSpex.flexible_spec[i],type,v);
                }
            },
            confirmAdd(e,ind){
                e.map(v => {
                    v.typeDesc = v.type.desc;
                    v.type = v.type.key;
                })
                const res = this.flexibleSpex.flexible_spec[ind];
                if(!res.interests || !(res.interests instanceof Array)) res.interests = [];
                e.map(v=>{
                    let index = res.interests.findIndex(int => int.id == v.id);
                    if(index < 0){
                        res.interests.push(v)
                    } else {
                        res.interests.splice(index,1,v);
                    }
                });
                this.$refs.flexibleSpec.map(v=>{
                    if(v.flexibleIndex == ind){
                        v.appendOptionValues(e)
                    }
                });
                this.$set(this.flexibleSpex.flexible_spec,ind,res);
                this.$nextTick(()=>{
                    this.flexibleSpex = JSON.parse(JSON.stringify(this.flexibleSpex))
                })
            }
        },
    }
</script>
<style lang="scss" scope>
    .shouzhong {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-size: 14px;
            color: #606266;
            line-height: 40px;

            .name {
                flex: 120px 0 0;
                text-align: right;
                vertical-align: middle;
                float: left;
                padding: 0 12px 0 0;
            }

            .shouzhongCon {
                flex: 1;
                overflow: hidden;

                .list {
                    p {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .tools {
                            font-size: 12px;
                            color: #409EFF;

                            i {
                                cursor: pointer;
                            }

                            span {
                                margin: 10px;
                                cursor: pointer;
                            }
                        }
                    }

                    .item {
                        border: 1px solid #dddd;
                        border-radius: 3px;
                    }

                    .conItem {
                        padding: 10px;

                        .tags {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            font-size: 12px;
                            position: relative;

                            .tools {
                                position: absolute;
                                top: -10px;
                                right: 0px;

                                i {
                                    font-size: 12px;
                                    margin: 5px;
                                    cursor: pointer;
                                }
                            }

                            .title {
                                flex: 80px 0 0;
                                margin-right: 10px;
                                text-align: right;
                                color: #666;
                                font-weight: bold;
                            }

                            .el-tag {
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }

                            .tagList {
                                flex: 1;
                                overflow: hidden;
                            }
                        }
                    }

                }
            }
        }
</style>