<!--
 * @Author: zhengyajie 1152353063@qq.com
 * @Date: 2023-09-06 15:12:59
 * @LastEditors: zhengyajie 1152353063@qq.com
 * @LastEditTime: 2023-09-19 17:58:17
 * @FilePath: \广告项目\ad-management\src\views\adManagement\components\batchEdit\adset\europeInput.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-form
      :model="adSetInfo"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <!-- 广告受益方 -->
      <el-form-item label="广告受益方：">
        <el-input
          v-model="adSetInfo.dsa_beneficiary"
          placeholder="请输入广告收益方"
          @change="emitMsg('dsa_beneficiary',adSetInfo.dsa_beneficiary)"
        ></el-input>
        <el-tooltip
          class="item"
          effect="dark"
          placement="bottom"
        >
          <p slot="content">
            欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
          </p>
          <span class="tips">欧盟必填</span>
        </el-tooltip>
      </el-form-item>
      <!-- 广告受益方 -->
      <el-form-item label="广告赞助方：">
        <el-input
          v-model="adSetInfo.dsa_payor"
          placeholder="请输入广告赞助方"
          @change="emitMsg('dsa_payor',adSetInfo.dsa_payor)"
        ></el-input>
        <el-tooltip
          class="item"
          effect="dark"
          placement="bottom"
        >
          <p slot="content">
            欧盟包括：奥地利、比利时、保加利亚、塞浦路斯、克罗地亚、捷克、丹麦、爱沙尼亚、芬兰、法国、德国、希腊、匈牙利、爱尔兰、<br />意大利、拉脱维亚、立陶宛、卢森堡、马耳他、荷兰、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典
          </p>
          <span class="tips">欧盟必填</span>
        </el-tooltip>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    bidEuropeMsg: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      adSetInfo: JSON.parse(JSON.stringify(this.bidEuropeMsg)),
    };
  },
  computed: {
    ...mapState('initData', ['initData']),
  },
  watch: {
    bidEuropeMsg: {
      handler() {
        this.adSetInfo = JSON.parse(JSON.stringify(this.bidEuropeMsg));
        // this.targetTypeList = list[0].billingEvents;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitMsg(key,v) {
      if(this.getStrlength(v)>50){
        this.$message({
          type:'info',
          message:'长度超过50字节'
        })
      }
      this.adSetInfo[key] = this.subSliceStr(this.adSetInfo[key])
      let msg = JSON.parse(JSON.stringify(this.adSetInfo));
      this.$emit('getBidEuropeMsg', { ...msg });
    },
    // 获取字节长度
    getStrlength(str){
      var count = 0;
      for(var i=0;i<str.length;i++){
        var c = str.charAt(i);
        if(/^[\u0000-\u00ff]$/.test(c)){
          count++
        }else{
          count+=2
        }
      }
      return count
    },
    // 截取字符串50个字节长度
    subSliceStr(str){
      var count = 0;
      for(var i=0;i<str.length;i++){
        var c = str.charAt(i);
        if(/^[\u0000-\u00ff]$/.test(c)){
          count++
        }else{
          count+=2
        }
        if(count>50){
          return str.substring(0,i)
        }
      }
      return str
    },
  },
};
</script>
<style lang="scss" scoped></style>
